import React from 'react'
import Heading from '../Heading/Heading'
import { latest } from '../../../assets/data/data'

import { AiOutlineUser, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai"
import {Link } from 'react-router-dom'
const Latest = () => {
  // const nav=useNavigate()
  return (
    <>
    <Heading title="Latest News"/>
    
    {latest.map((item)=>(

     
      <Link to={item.destination}>
    <div className="flight  top" key={item.id}  >
      
       <div className="img2" > 
      
       <img src={item.cover} alt=""/>
       </div>
       <div className="description">
         <h6>{item.title.slice(0,100)}</h6>
         {/* <div className='date1'>
          <div><AiOutlineUser className='icon' /> <label htmlFor=''>{item.category}</label></div> 
         <div> < AiOutlineClockCircle className='icon1' /> <label htmlFor=''>{item.date}</label></div> 
         
         </div> */}
         <p>{item.desc}</p>
       </div>
       
     </div> </Link>

) )}
            

    </>
  )
}

export default Latest