import React from "react";
import { latest } from "../../../assets/data/data";



const Singleleft = () => {
  const selectedItem = latest.find((item) => item.id === 2);

  return (
    <div>
      <div className="Single" key={selectedItem.id}>
        <div className="img5">
          <img src={selectedItem.cover} alt="" />
        </div>

        <h2>{selectedItem.title}.</h2>
        <p>
        {selectedItem.desc}
        </p>
       
      </div>
    </div>
  );
};

export default Singleleft;
