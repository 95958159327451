import React from 'react'
import "./Tranding.css"
import Blog from "../../Blog/Tranding/Blog/Card"
import Heading from '../Heading/Heading'
const Tranding = () => {
  return (
<>
<div className="containerHeadt">
<div className="containert">

<Heading title="Trending"/>
<Blog/>
</div>
</div>
</>
  )
}

export default Tranding