import React from "react"
import "./blog.css"
import { blog } from "../../../../assets/data/data"
import img from "../../../../assets/data/image1.png"

import { AiOutlineUser, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai"
import { Link } from "react-router-dom"


export const Card = () => {
  return (
    <>
    <section className='blog'>  

        <div className='containert grid3'>
       
          {blog.map((item) => (
            <div className='box boxItems' key={item.id}>
              <div className='img'>
                <img src={item.cover} alt='' />
              </div>
              <div className='details'>
                {/* <div className='tag'>
                  <AiOutlineUser className='icon' />
                  <a href='/'>{item.category}</a>
                </div> */}
                <Link to={`/single${item.id}`} className='link'>
                  <h3>{item.title.slice(0, 75)}...</h3>
                </Link>
                <p>{item.desc.slice(0, 95)}...</p>
                <div className='date'>
                  <AiOutlineClockCircle className='icon' /> <label htmlFor=''>{item.date}</label>
                  {/* <AiOutlineComment className='icon' /> <label htmlFor=''>27</label>
                  <AiOutlineShareAlt className='icon' /> <label htmlFor=''>SHARE</label> */}
                </div>
              </div>
            </div>
          ))}
        </div>

       
      </section>
    </>
  )
}


export default Card;
