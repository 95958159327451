
import React from "react";




import Playstore from "../Flight/Playstore";
import Offer from "../Offer/Offer";
import Secondleft from "./Secondleft";



const Single = () => {



  return (
    <>
      <div className="containerHeadt">
        <div className="containert">
          <div className="division">
            <div className="leftsides">
              <Secondleft/>
               
              
            </div>
            <div className="rightsides">
              <Offer/>
            
              <Playstore/>
            
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Single;
