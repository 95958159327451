import React from 'react'

const First = () => {
  return (
    // <div className='container'>
    <div className="max-w-[81vw] mx-auto px-[16px] sm:px-[32px]">
    <div className="py-8">
      <div>
        <h2 className=" text -[16px] sm:text-[24px] font-semibold leading-tight">Invoices</h2>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs md:text-[16px] font-semibold text-gray-700
                 uppercase tracking-wider"
                >
                  S.N
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs md:text-[16px] font-semibold text-gray-700 
                uppercase tracking-wider"
                >
                  Mode of Transaction
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs md:text-[16px] font-semibold text-gray-700 
                uppercase tracking-wider"
                >
                  Amount
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs md:text-[16px] font-semibold text-gray-700
                 uppercase tracking-wider"
                >
                  Charges
                </th>
              </tr>
            </thead>
            <tbody>
              {/* static table rows:*/}
              <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <div className="flex">
                    <div className="ml-3">
                      <p className="text-gray-900 text-xs md:text-[15px]  font-semibold whitespace-no-wrap">
                        A
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 text-xs md:text-[15px] whitespace-no-wrap">
                    Bank Transfer
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex flex-col gap-[5px] mt-[px]">
                  <p className="text-gray-600 my-3 text-xs md:text-[15px] whitespace-no-wrap border-b-[1px] border-gray-300">
                    Upto Rs 1000
                  </p>
                  <p className="text-gray-600 my-3 text-xs md:text-[15px] whitespace-no-wrap border-b-[1px] border-gray-300">
                    Rs 1001 to 3000
                  </p>
                  <p className="text-gray-600 my-3 text-xs md:text-[15px] whitespace-no-wrap border-b-[1px] border-gray-300">
                    Rs 3001 to 10000
                  </p>
                  <p className="text-gray-600 my-3 text-xs md:text-[15px] whitespace-no-wrap border-b-[1px] border-gray-300">
                    Above Rs 10001
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <span className="relative  px-3 py-1 font-semibold text-green-900 leading-tight flex flex-col gap-[5px]">
                    <span className="relative border-b-[1px] my-3 text-xs md:text-[15px] border-gray-300 ">
                      Free
                    </span>
                    <span className="relative  border-b-[1px] my-3 text-xs md:text-[15px] border-gray-300">
                      Rs 5
                    </span>
                    <span className="relative border-b-[1px] my-3 text-xs md:text-[15px] border-gray-300">
                      Rs 10
                    </span>
                    <span className="relative border-b-[1px] my-3 text-xs md:text-[15px] border-gray-300">
                      Rs 30
                    </span>
                  </span>
                </td>
              </tr>{" "}
              <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex">
                    <div className="ml-3">
                      <p className="text-gray-900 text-xs md:text-[15px] font-semibold whitespace-no-wrap">
                        B
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 text-xs md:text-[15px] whitespace-no-wrap">
                    Card Load
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <p className="text-gray-600 text-xs md:text-[15px] whitespace-no-wrap">
                    1 Transaction Limit
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <span className="relative  px-3 text-xs md:text-[15px] py-1 font-semibold text-green-900 leading-tight flex flex-col">
                    <span className="relative">3.50%</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex">
                    <div className="ml-3">
                      <p className="text-gray-900 text-xs md:text-[15px] font-semibold whitespace-no-wrap">
                        C
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 text-xs md:text-[15px] whitespace-no-wrap">
                    NEA Electricity Bill
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <p className="text-gray-600 whitespace-no-wrap"> {}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                  <span className="relative text-xs md:text-[15px]  px-3 py-1 font-semibold text-green-900 leading-tight flex flex-col">
                    <span className="relative">5 (Extra)</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  {/* </div> */}
  </div>
  )
}

export default First