import React from 'react'
import Banner from '../Banner/Banner'
import Tranding from './Tranding/Tranding'
import Flight from './Flight/Flight'

const Blog = () => {
  return (
    <div>
       <Banner/>
       <Tranding/>
       <Flight/>
    </div>
  )
}

export default Blog