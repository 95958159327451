import React from "react";

const Servicess = () => {
  const list = [
    {
      id: 1,
    //   img: require("../assests/ebanking.png"),
    img: require("../../assests/ebanking.png"),
      alt: "E-banking",

    },
    {
      id: 2,
      img: require("../../assests/mobile.png"),
      alt: "mobile banking",
    },
    {
      id: 3,
      img: require("../../assests/connectIps.png"),
      alt: "Connect Ips",
    },
    {
      id: 4,
      img: require("../../assests/wallet.png"),
      alt: "Load Wallet",
    },
    {
      id: 5,
      img: require("../../assests/BankTransfer.png"),
      alt: "Bank Transfer",
    },
    {
      id: 6,
      img: require("../../assests/wallettowallet.png"),
      alt: "Wallet-Wallet",
    },
    {
      id: 7,
      img: require("../../assests/utilityPayment.png"),
      alt: "Utility Payment",
    },
    {
      id: 8,
      img: require("../../assests/requestFund.png"),
      alt: "Requests Fund",
    },
  ];
  return (
    <div className="max-w-[1280px] px-[24px] mx-auto">
      <div className="flex flex-col justify-center items-center w-full gap-[10px] my-[48px]">
        {/* header */}
        <div className="text-[30px]  font-bold tracking-tight text-gray-900 sm:text-[36px]
         mb-[16px]">
          Services
        </div>
        <div
          className="max-w-full text-center text-[20px] leading-[28px] text-gray-500 mb-[24px] 
        "
        >
          The d-Paisa system enables partner merchants to perform transactions
          and receive money from customers having a d-Paisa account in a secure
          environment. You can perform various type of transactions such as load
          unload, utility payments, bills payments.
        </div>
        {/* Ourserc=vices Logo */}
        <div
          className="-mx-[24px] grid grid-cols-2 gap-0.5 overflow-hidden
         sm:mx-0 sm:rounded-[16px] md:grid-cols-4 w-full"
        >
          {list.map(({ id, img, alt }) => (
            <div key={id} className="bg-gray-100 p-[32px] sm:p-[40px] grid ">
              <img
                className="max-h-[48px] w-full object-contain"
                src={img}
                alt={alt}
              />
              <p className="mx-auto my-auto text-[24px] leading-[36px] text-gray-800">
                {alt}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Our Partner Merchants (E-Banking): */}
      <div></div>
    </div>
  );
};

export default Servicess;