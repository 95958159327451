import React from "react";

import { tpost } from "../../../../assets/data/data";

const Singleleft = () => {
  const selectedItem = tpost.find((val) => val.id === 1);

  return (
    <div>
      <div className="Single" key={selectedItem.id}>
        <div className="img5">
          <img src={selectedItem.cover} alt="" />
        </div>

        <h2>{selectedItem.title}.</h2>
        <p>
        Greetings, Esteemed Clients<br></br>
With the dPaisa Cashback Bonanza, your pass to amazing rewards with every purchase, get ready to up your savings game! We are excited to present an array of enticing cashback incentives across a variety of services, exclusively for our valued users, as the top mobile wallet provider in Nepal.<br></br>
A preview of our amazing cashback deals may be found here: 

        </p>
        <p>
          <span>Reward for Mobile Top-Up: Up to 2% </span>Your mobile balance will never run out again! By offering up to 2% cashback on every cell top-up, dPaisa makes sure you can stay connected without going over budget. It's never been simpler to earn cashback, whether you're topping off your own phone or gifting credit to a friend or relative.
        </p>
        <p>
          <span>Booking flights: Cashback of Rs. 80 is fixed </span>Are you planning your next vacation? With dPaisa's flight booking service, you can make it happen and get a cashback of Rs. 80 on each reservation. This cashback offer is your pass to lower airfare whether you're traveling for work or pleasure.
        </p>

        <p>
          <span>Online Recharge: Cashback of up to 0.4% </span>With dPaisa's internet recharge service, you can stay connected and online and now get up to 0.4% cashback on every purchase. You can stay connected without going over budget with this cashback deal, whether you're topping off your mobile data plan or home broadband.
        </p>
        <p>
          <span>TV Recharge: Cashback of up to 1.5% </span> Never miss another one of your favorite shows! With every transaction, dPaisa TV recharge service offers up to 1.5% reward. With this cashback offer, you can be confident that you're getting more enjoyment for your money whether you're refilling your cable or satellite TV subscription.
        </p>
      </div>
    </div>
  );
};

export default Singleleft;
