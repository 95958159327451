import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./HelpAndSupport.module.css";
import { FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Support } from "../../../assets/data/data";
import Dialog from "./HelpAndSupportModal";

const HelpAndSupport = () => {
  const [visibleItems, setVisibleItems] = useState(5);
  const [viewMore, setViewMore] = useState(false);
  const navigate = useNavigate();
  const handleBoxItemClick = (helpandsupport) => {
    navigate(`/support/${helpandsupport}`);
  };

  const handleViewMore = () => {
    if (viewMore) {
      setVisibleItems(5);
    } else {
      setVisibleItems(Support.length);
    }
    setViewMore(!viewMore);
  };

  return (
    <>
      <div className="containerHead6">
        <div className="container6">
          <div className={Styles.subContainer}>
            <div className={Styles.introduction}>
              <h1 className={Styles.intro}>Help and Support</h1>
            </div>

            <div className={Styles.services}>
              <div className={Styles.register}>
                <h1>Registered Office</h1>
                <p>Dpaisa Building</p>
                <p>Minbhawan,NewBaneshor,Kathmandu</p>
              </div>
              <div className={Styles.customerSupport}>
                <h1>Customer Support</h1>
                <p>Toll-Free No: <b>16600119775</b>(For NTC only)</p>
                <p>Toll-Free No: <b>18102000001</b>(For Ncell only)</p>
                <p>Email:care@dpaisa.com</p>
                <p>(For general inquiry and technical assitance)</p>
              </div>
            </div>
            <div className={Styles.notice}>
              <p>
                <b>Note:</b> Users that have not been verified cannot send or
                receive money.
                <p><b>Note:</b> To serve you We are here available for 16 hours.</p>
              </p>
            </div>

            <section className={Styles.products}>
              <h2>
                Choose a service you need help with
              </h2>
              <div className={Styles.AllProducts}>
                {Support.slice(0, visibleItems).map((Support, index) => (

                  <div className={Styles.product}
                    onClick={() => handleBoxItemClick(Support.title)}
                  >
                    <img src={Support.Image}></img>
                    <div class={Styles.ProductInfo}>
                      <p class={Styles.ProductTitle}>{Support.subtitle}
                      </p>
                      <p class={Styles.ProductPrice}>{Support.subtitle1}</p>
                      <a class={Styles.ProductBtn} href="#">{Support.title}</a>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          
            <div className={Styles.listing}>
              <div className={Styles.plusMoreSecondRow}>
                <button
                  type="button"
                  onClick={handleViewMore}
                  className={Styles.btn}
                >

                  {viewMore ? "Viewless" : "Viewmore"}
                  <div className={Styles.icons}>
                    {viewMore ? (
                      <div className={Styles.cross}>
                        <ImCross />
                      </div>
                    ) : (
                      <FaPlus />
                    )}
                  </div>

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default HelpAndSupport;
