import React from "react";
import Style from "../Transitionlimit/transitionlimit.module.css";
const comission = () => {
  return (
    <div className="containerHead6">
      <div className="container6">
        <div className={Style.container}>
          <div>
            <div className={Style.title}>
              <h2>Transaction Limit</h2>
            </div>

            <div>
              <div className={Style.subTitle}>
                <h3>Transaction Limits for Customers</h3>
              </div>

              <div className={Style.Customer}>
                <p>
                  Dear Customers, <br />
                  We would like to inform you that the transaction limits for
                  the types of transactions listed below have been updated in
                  accordance with the Nepal Rastra Bank Payment System.
                </p>
              </div>
            </div>
            <div>
              <div className={Style.walletBalance}>
                <h3>1.Maximum Wallet Balance at End of the Day:</h3>
              </div>

              <div className={Style.content}>
                <p>
                  Users are permitted to retain a balance up to the specified
                  limits in the wallet at the end of each day. Any balance that
                  exceeds this limit must be spent or withdrawn before the day
                  concludes.
                </p>
                <div className={Style.description}>
                  <div className={Style.descriptionLeft}>
                    <h3>Description</h3>
                    <p>Maximum Wallet Balance at end of the day.</p>
                  </div>
                  <div className={Style.descriptionRight}>
                    <h3>Amount</h3>
                    <p>NRS. 50,000/-</p>
                  </div>
                </div>
              </div>

              <div className={Style.walletBalance}>
                <h3>2.Wallet to Wallet Transfer:</h3>
              </div>
              <div className={Style.content}>
                <p>
                  According to the threshold below, verified users are able to
                  conduct peer-to-peer (P2P) transfers, whereas unverified users
                  are not able to do so.
                </p>
              </div>
            </div>
            <div className={Style.sendReceive}>
              <div className={Style.sendingLimit}>
                <h3>Sending Limit</h3>
                {/*Sending limit */}
                <div className={Style.sendBoxes}>
                  <div className={Style.boxOne}>
                    <h5 className={Style.hideen}>
                      Amount per Txn <br /> (NRs.)
                    </h5>
                    <p>Verified</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per Txn <br /> (NRs.)
                    </h5>
                    <p>25,000</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per Day <br /> (NRs.)
                    </h5>
                    <p>50,000</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Count per <br /> day{" "}
                    </h5>
                    <p>10</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per month <br /> (NRs.)
                    </h5>
                    <p>5,00,000</p>
                  </div>
                </div>
              </div>
              {/*Receiving limit */}
              <div className={Style.receivingLimit}>
                <h3>Receiving Limit</h3>
                <div className={Style.receiveBoxes}>
                  <div className={Style.boxOne}>
                    <h5 className={Style.hideen}>
                      Amount per <br /> Txn (NRs.)
                    </h5>

                    <p>Verified</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per <br /> Txn (NRs.)
                    </h5>

                    <p>25,000</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per <br /> Day (NRs.)
                    </h5>

                    <p>50,000</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Count per
                      <br /> day
                    </h5>

                    <p>10</p>
                  </div>
                  <div className={Style.boxOne}>
                    <h5>
                      Amount per <br /> month (NRs.)
                    </h5>

                    <p>5,00,000</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={Style.paymentLimit}>
              <h3>3.Transaction Though Agents:</h3>
            </div>
            <div className={Style.paymentLimitContent}>
              <b>1.Wallet load</b>
              <p>a. Per Day: 25,000</p>
              <p>b. Per Month: 1,00,000</p>
              <b>1.Cash Out</b>
              <p>a. Per Day: 5,000</p>
              <p>b. Per Month: 25,000</p>
            </div>

            <div className={Style.withdrawal}>
              <h3>4.Bank To Wallet Transfer</h3>
            </div>
            <div className={Style.withdrawalContent}>
              <div className={Style.withdrawalDesc}>
                <div className={Style.withdrawalLeft}>
                  <h3>Description</h3>
                  <p>The maximum amount that one transaction can have.</p>
                  <p>Maximum Transaction Amount Per Day</p>
                  <p>Maximum Transaction Count Per Day</p>
                  <p>Maximum Transaction Amount Per Month</p>
                </div>
                <div className={Style.withdrawalRight}>
                  <h3>Verified</h3>
                  <p>NRS. 50,000/-</p>
                  <p>NRS. 2,00,000/-</p>
                  <p> 10</p>
                  <p>NRS. 10,00,000/-</p>
                </div>
              </div>
              <div className={Style.userNotice}>
                <p className={Style.second}>
                  <b>Note:</b> Users who have not been verified cannot complete
                  the withdrawal transaction.
                </p>
              </div>
            </div>

            <div className={Style.accountLink}>
              <h3>5.Transaction Limit For QR Merchant:</h3>
            </div>
            <div className={Style.accountLinkContent}>
              <p>
                {" "}
                Non-PAN Holder Merchant: 5,000 Per Day
              </p>             
          
              <div className={Style.userNotice}>
                <p className={Style.userNoticeFirst}>
                  <b>Note:</b> QR Transaction at Point of Sales/Point of Transaction: Per Day Rs 3,00,000
                  (Not applicable for Government Tax Payment, Revenues, Fines, Vehicle Tax, Electricity, Drinking Water Supply, Social Security Fund, Insurance Premium, Stock Payment etc., )
                </p>
              </div>
            </div>

            {/* <div className={Style.walletBalance}>
              <h3>6. Wallet Balance:</h3>
            </div> */}
            {/* <div className={Style.walletBalanceContent}>
              <div className={Style.walletBalanceDesc}>
                <div className={Style.walletBalanceLeft}>
                  <p>Unverified User</p>
                  <p>Verified User</p>
                </div>
                <div className={Style.walletBalanceRight}>
                  <p>NRS. 30,000</p>
                  <p>NRS. 500,000</p>
                </div>
              </div>
              <div className={Style.userNotice}>
                <p className={Style.walletBalanceNotice}>
                  Please be aware that users cannot add more money to their
                  wallets than the amount specified above.
                </p>
                <p>
                  Thank you, <br />
                  DpaisaLtd.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default comission;
