
import React, { useState } from "react";
import "./register.css";
import img from "../../images/Register Page1.png";
import img1 from "./../../images/dPaisa Logo_Black_.png";
import img2 from "../../images/Login Page Icons/Mobile  Phone Icon.png";
import img22 from "../../images/Register Icon/Name Icon.png";
import img23 from "../../images/Register Icon/Email.png";
import img24 from "../../images/Register Icon/Birthday_.png";
import img25 from "../../images/Register Icon/Gender.png";
import img3 from "../../images/Login Page Icons/Lock.png";
import img4 from "../../images/Login Page Icons/Facebook.png";
import img5 from "../../images/Login Page Icons/FB Messanger.png";
import img6 from "../../images/Login Page Icons/Instagram.png";
import img7 from "../../images/Login Page Icons/Linkidn.png";
import img8 from "../../images/Login Page Icons/Youtube.png";
import img9 from "../../images/Login Page Icons/X.png";

const Register = ({ open1, onClose1 }) => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  if (!open1) return null;

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDobChange = (e) => {
    setDob(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    console.log("Full Name:", fullName);
    console.log("Phone Number:", phoneNumber);
    console.log("Email:", email);
    console.log("Date of Birth:", dob);
    console.log("Gender:", gender);
    console.log("Password:", password);
    console.log("Confirm Password:", confirmPassword);
  };

  return (
    <>
      <div className="overlay">
        <div className="modelContainer">
          <div className="m">
            <img className="model1" src={img} alt="" />
          </div>
          <div className="modelRight">
            <p onClick={onClose1} className="closeBtn">
              X
            </p>
            <div className="content1">
              <div className="with1">
                <h1>Sign Up with</h1>
                <img src={img1} alt="" />
              </div>
              <div className="btnContainer1">
                <form onSubmit={handleSubmit}>
                  <div className="but1">
                    <div className="form-group1">
                      <img src={img22} alt="" />
                      <input
                        type="text"
                        id="fullName"
                        value={fullName}
                        placeholder=" Full Name*"
                        onChange={handleFullNameChange}
                        required
                      />
                    </div>

                    <div className="form-group1">
                      <img src={img2} />
                      <input
                        type="Number"
                        id="phoneNumber"
                        value={phoneNumber}
                        placeholder=" Mobile Number*"
                        onChange={handlePhoneNumberChange}
                        required
                      />
                    </div>
                    <div className="form-group1">
                      <img src={img23} />
                      <input
                        type="email"
                        id="email"
                        value={email}
                        placeholder=" Email*"
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                    <div className="form-group1">
                      <img src={img24} />
                      <input
                        type="date"
                        id="dob"
                        value={dob}
                        placeholder=" Date Of Birth*"
                        onChange={handleDobChange}
                        required
                      />
                    </div>
                    <div className="form-group1 ">
                    <img src={img24} />
                      <span className="gender-label">Gender:</span>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            checked={gender === "male"}
                            onChange={handleGenderChange}
                            required
                          />{" "}
                          Male
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            checked={gender === "female"}
                            onChange={handleGenderChange}
                            required
                          />{" "}
                          Female
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="other"
                            checked={gender === "other"}
                            onChange={handleGenderChange}
                            required
                          />{" "}
                          Other
                        </label>
                      </div>
                    </div>
                    <div className="form-group1">
                      <img src={img3} />

                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                      {passwordError && (
                        <div className="error" style={{  width:"100%",color: "orange" }}>
                          {passwordError}
                        </div>
                      )}
                    </div>
                    <div className="form-group1">
                      <img src={img3} />

                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          required
                        />
                      </div>
                      {passwordError && (
                        <div className="error" style={{width:"100%", color: "orange" }}>
                          {passwordError}
                        </div>
                      )}
                    </div>

                    <div className="remember">
                      <div className="inn">
                        <p>By Signing Up you agree to the dapaisa</p>

                        <span style={{fontSize:"12px", color: "blue", cursor: "pointer" }}>
                          Terms & Conditions
                        </span>
                      </div>
                    </div>
                    <button className="l" type="submit">
                      Sign Up
                    </button>
                  </div>
                  <div className="connect1">
                    <p>Connect with us</p>
                    <div className="conn">
                      <img src={img4} alt="" />
                      <img src={img5} alt="" />
                      <img src={img6} alt="" />
                      <img src={img7} alt="" />
                      <img src={img8} alt="" />
                      <img src={img9} alt="" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
