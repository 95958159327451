import React, { useState } from "react";
import img from "../../images/Login Page Left Side.png";

import img1 from "./../../images/dPaisa Logo_Black_.png";
import img2 from "../../images/Login Page Icons/Mobile  Phone Icon.png";
import img3 from "../../images/Login Page Icons/Lock.png";
import img4 from "../../images/Login Page Icons/Facebook.png";
import img5 from "../../images/Login Page Icons/FB Messanger.png";
import img6 from "../../images/Login Page Icons/Instagram.png";
import img7 from "../../images/Login Page Icons/Linkidn.png";
import img8 from "../../images/Login Page Icons/Youtube.png";
import img9 from "../../images/Login Page Icons/X.png";
const Login = ({ open, onClose }) => {
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  if (!open) return null;

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return;
    }

    console.log("Phone Number:", phoneNumber);
    console.log("Password:", password);
  };

  return (
    <>
      <div  className="overlay">
        <div className="modelContainer">
          <div className="m">
            <img className="model" src={img} alt="" />
          </div>
          <div className="modelRight">
            <p onClick={onClose} className="closeBtn">
              X
            </p>
            <div className="content">
                
              <div className="with">
                <h1>Login with</h1>
                <img src={img1} alt="" />
              </div>
              <div className="btnContainer">
                <form onSubmit={handleSubmit}>
                    <div className="but1">
                  <div className="form-group">
                    <img src={img2} />
                    <div className="number-input">
                    <input
                      type="text"
                      id="phoneNumber"
                      value={phoneNumber}
                      placeholder=" Email / Number"
                      onChange={handlePhoneNumberChange}
                      required
                    />
                    </div>
                   
                  </div>
                  <div className="form-group">
                    <img src={img3} />

                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      {/* <button
                          type="button"
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button> */}
                    </div>
                    {passwordError && (
                      <div className="error">{passwordError}</div>
                    )}
                  </div>

                  <div className="remember">
                    <div className="in">
                    <input type="checkbox" id="rememberMe" name="rememberMe" />
                    <label for="rememberMe">Remember me</label>
                    </div>
                    <p>Forget Password ?</p>
                    
                  </div>
                  <button className="l" type="submit">Login</button>
                 <div className="sign">
                  <p>Don't have an account? <span style={{color:"blue", cursor:"pointer"}}>SignUp</span></p>
                 </div> 
                </div>
                <div className="connect">
                  <p>
                    Conncet with us

                  </p>
                  <div className="conn">
                  <img src={img4} />
                  <img src={img5} />
                    <img src={img6} />
                    <img src={img7} />
                    <img src={img8} />
                    <img src={img9} />
                  
                    
                  </div>


                </div>

              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
