import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <>
      <div className="containerHead6">
        <div className="container6">
          <div className="privacy">
            <h1>Privacy and Policy</h1>
          </div>
          <h2>Introduction</h2>
          <p>
            Welcome to the Privacy Policy website of Goldmine Business Group
            (dPaisa). We are ecstatic to have you join us on this journey to
            revolutionize Nepalese online payment methods. Goldmine Business
            Group (dPaisa), the authorized distributor of recharge cards for
            Nepal Telecom (NTC), has grown into a major player in the financial
            technology industry. The launch of dPaisa, a ground-breaking mobile
            wallet and online payment system, commemorated our founding. You may
            quickly transfer money, make payments, and take care of your utility
            bills with dPaisa. Sending and receiving money won't require you to
            wait in lengthy lineups or figure out tricky procedures any more.
            Our goal remains unwavering: to completely transform online payments
            in Nepal's rural as well as urban areas. In the field of financial
            technology, we see ourselves as trailblazers committed to creating a
            trustworthy online banking environment that the people of Nepal can
            fully rely on. The creation of dPaisa, a complete online payment
            gateway that is seamlessly integrated with a mobile wallet, is a key
            component of our growth strategy. Through our wallet platform, we
            facilitate simple utility payments with our state-of-the-art mobile
            payment solutions. Using techniques like SCT cards, mobile
            companying, and e-companying makes funding your wallet simple.
            Furthermore, we offer utility payment services, including NEA bill
            settlements and NTC balance top-ups. Security is of the utmost
            importance as we set out on this journey of transformation. For the
            benefit of our users, we are dedicated to maintaining the greatest
            levels of security and confidentiality. Our goal is to always
            improve our services and satisfy our clients' changing needs, which
            motivates us to welcome innovation as a continual partner. We invite
            you to investigate our offerings, enjoy the convenience of
            electronic payments, and work with us to transform Nepal's financial
            environment. This Privacy Policy describes how we protect your
            privacy and how we handle the information you give to us. We value
            your trust and are committed to making sure that both your online
            experience with us and the security of your personal information are
            maintained. We are honored that you have selected Goldmine Business
            Group (dPaisa) as your digital financial partner.
          </p>
          <h2>Information Gathering</h2>
          <p>
            By giving us your information, you agree that it will be collected,
            stored, processed, disclosed, and/or transferred, in accordance with
            the company's policies listed here, as well as any requirements that
            may arise during regular business operations and/or as allowed by
            applicable laws and regulations.
          </p>
          <ul>
            <li>Personal Information: By giving us your information, you agree that it will be collected, stored,
processed, disclosed, and/or transferred, in accordance with the company's policies listed here,
as well as any requirements that may arise during regular business operations and/or as allowed
by applicable laws and regulations.</li>
            <li>Your account information: Along with other demographic data, your name, parent's name,
address, phone number, email address, date of birth, gender, and marital status, passwords and
biometric data are all required.</li>
            <li>Any additional data that you supply in accordance with the "Know your customer" guidelines
as mandated by relevant regulations.
</li>
<li>Data about transactions completed on dPaisa platforms; payment instrument specifics are not
included.

</li>
<li>
Financial data, including bank account, credit card, debit card, and other payment method
specified
</li>
<li>
Non-Personal Data: When you use a URL, smartphone, or other mobile device to access dPaisa
platforms, we and our service providers may gather non-personal data. This data won't reveal
"you" as an individual.

</li>
<li>
    
Information about your computer and devices: This comprises specifics about your computer,
mobile device, and Internet connection, including your IP address, platforms, operating
systems, type of browser, and other browsing data. In addition, it contains the phone number,
phone network information, device type, and unique device identifier.
    
</li>
<li>
Location data: Our applications will obtain the most recent location information for your
device when permitted.
    
</li>
<li>
Mobile device IDs: If you use our Services on a mobile device, we may use mobile device IDs
in place of cookies to identify you. We might keep track of your usage of our apps and save
your preferences in this way. Mobile device IDs cannot be removed, in contrast to cookies.
    
</li>
<li>Information about your use of the Services: Information about how you have used the
services, such as the time and date of your visits, the search terms you have used, your
comments, domain names, and the search results you have selected. It also includes error
logs and other relevant data.
</li>
<li>
Your preferences: You can change the language and time zone, among other things.

</li>
<li>
The one-time password (OTP) that you were provided to use exclusively for dPaisa platform
transactions by your issuing bank or dPaisa platforms.
</li>
<li>The remarks you leave on message boards, chat rooms, and other message/feedback areas on
social media and dPaisa platforms.
</li>
<li>
Information may be collected from you while you browse or use dPaisa Platforms in a
number of ways and formats using
<li>Participating in any other action that would fall within the terms and conditions
specified there, or registering as a "user" or "merchant" on dPaisa platforms.
</li>
<li>Making use of dPaisa platforms for attempted or completed transactions,
gaining access to dPaisa platforms' emails, notifications, links, and other content.
In any other case, interacting with any "dPaisa Entities".
</li>
</li>


          </ul>



          <h2>How are the Information used?</h2>
          <p>The following uses are possible for any information we gather from you:
This allow us to present the kind of material and product options that most interest you and to
customize your experience on our website. </p>
<ul>
    <li>
    To make changes to our website so that we can better assist you.
    </li>
    <li>It enables us to handle your customer service inquiries more effectively.

    </li>
    <li>To manage a poll, promotion, competition, or other website feature.
        
    </li>
    <li>To handle your transactions as soon as possible
        
    </li>
    <li>To periodically send emails about your order or other goods and services.
        
    </li>
  
</ul>
<h2>How is your information protected by us? </h2>
<p>
To ensure that your visit to our site is as safe as possible, we regularly examine it for security
vulnerabilities. Your personal information is kept private behind guarded networks and is only
available to a select group of people who are obliged to maintain the information's confidentiality
and have special access rights. Additionally, Secure Socket Layer (SSL) technology encrypts any
credit and sensitive data you submit. While we do not keep credit or debit card information on file,
we do retain SCT Card information with user consent. To protect your personal information, we
put in place a number of security measures whenever a user puts an order, enters, submits, or
accesses their data.


</p>
<h2>Third Party Disclosures
</h2>
<p>Your personally identifiable information is never sold, traded, or otherwise transferred to
unaffiliated third parties unless we provide you prior notice. As long as those parties agree to keep
this information confidential, this excludes website hosting partners and other parties that help us
run our website, run our business, or serve you. Additionally, we could disclose your information
if we think it's necessary to abide by the law, uphold our site's rules, or safeguard the safety,
property, or rights of others.
<br></br>
In the event of any suspicious or illicit transactions, dPaisa retains the right to suspend, block,
withhold money, and notify law police. You consent to law enforcement authorities having access
to your information, including financial, personal, and any other transactional data. Non-personally
identifiable visitor data, however, might be given to other organizations for advertising, marketing,
or other purposes.
</p>
<h2>Third party links and services
</h2>
<p>
There can be links to external websites within the services. Depending on the feature, using it may
lead to the collection, processing, or transfer of personal data about you. Please be advised that
although other websites or services may be linked from our services, we have no control over their
privacy policies or content. Regarding websites or services operated by third parties, we neither
recommend nor make any statements. The information you voluntarily give to or that these third
parties acquire is not covered by our privacy policy. It is highly recommended that you review the
privacy policies of these third parties.

</p>
<h2>Cookies
</h2>
<p>When a visitor comes to a website, their browser sends it a string of information called a cookie,
which is stored on their computer. Cookies are used by dPaisa to track visitors, how they use the
website, and how they like to access it. The disadvantage of this is that some aspects of dPaisa's
websites might not work correctly without cookies. Users who do  not want cookies to be installed
on their computers should set their browsers to reject cookies before accessing dPaisa's websites. </p>
<h2>
Permissible Ages
</h2>
<p>
Unless allowed by applicable local legislation, users under the age of 16 are not entitled to use the
Services. We don't intentionally gather personal data from users, advertise to, or ask for
information from those who are younger than the legal age. We shall promptly remove the account
and any associated data if we learn that the person providing personal information is not of legal
age
</p>
<h2>Amendments</h2>
<p>dPaisa reserves the right to make changes to its Privacy Policy at any moment, even though the
majority are probably going to be minimum. Updates and modifications will take effect as soon as
they are posted on this page. You should periodically check our Privacy Policy for updates. Any
modifications to this Privacy Policy will be deemed accepted by you if you continue to use this
website.</p>
<h2>User Consent</h2>
<p>Through your use of dPaisa and/or information submission, you agree to the collection and use of
the data you share on dPaisa in compliance with this privacy policy. This includes, but is not
limited to, your permission to information sharing.</p>


        </div>
      </div>
    </>
  );
};

export default Privacy;
