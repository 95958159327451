import { useEffect, useState } from "react";
import { Disclosure, Switch } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FAQs() {
  const [enabled, setEnabled] = useState(false);

  const Engfaqs = [
    {
      id: 1,
      question: "What is a wallet? Or what is an electronic wallet?",
      answer:
        "Wallet is an electronic account issued by a payment service provider approved by Nepal Rastra Bank in the name of its customers. Wallet users can deposit funds in the wallet from designated banks and financial institutions or agents and pay for the purchase of goods and services.",
    },
    {
      id: 2,
      question: "What is e-money?",
      answer:
        "Physical cash in banks and financial institutions, which can be transacted through electronic means, is called e-money. E-money is also called Virtual Money.",
    },
    {
      id: 3,
      question:
        "What precautions should be taken while conducting electronic transactions?",
      answer:
        "Users should not provide their Secret Password, One Time Password (OTP) or sensitive details (eg; account number, date of birth, citizenship or passport number etc.) to other people by any means. You should change your wallet password and pin number from time to time and keep such passwords confidential. If the mobile device used for payment is stolen or lost, the relevant service provider should be informed immediately and the service should be blocked.",
    },
    {
      id: 4,
      question: "How much amount can be transferred through e-wallet?",
      answer:
        "Up to Rs.50 thousand per transaction, up to Rs.1 lakh per day and up to Rs.10 lakh per month can be transferred from the wallet. Nepal Rastra Bank may issue instructions from time to time and change the limit of money transfer.",
    },
    {
      id: 5,
      question: "What is Quick Response (QR) Code?",
      answer:
        "This is a special type of code that can be scanned by an electronic payment device to get the details stored in it. It contains the account details of the merchant receiving the payment. At present, as per the instructions of Nepal Rastra Bank, the customer is not charged any kind of fee when paying through QR Code. For example, by scanning the QR below, the definition mentioned above can be obtained.",
      image: require("../assests/barCode.jpg"),
    },
    {
      id: 6,
      question: "What is Point of Sale (PoS)?",
      answer:
        "Point of Sale (PoS) is a device that accepts electronic payments through the use of which various merchants or shops can receive payment for goods and services purchased or consumed by customers through various cards currently available.",
    },
    {
      id: 7,
      question: "What is Issuer Bank and Acquirer Bank?",
      answer:
        "A bank that issues any electronic payment device like payment card, QR is called Issuer Bank. If the electronic payment device issued by the Issuer Bank is used from another bank's terminal, the bank with that terminal is called the Acquirer Bank.",
    },
    {
      id: 8,
      question: "What are the fees for payment transactions?",
      answer:
        "According to the directives of Nepal Rastra Bank, the licensed institutions have determined the fees charged according to the nature of the payment transaction. The updated details of the fees determined in that way are published on the website of the concerned organization.",
    },
    {
      id: 9,
      question:
        "Are licensed banks and financial institutions from Nepal Rastra Bank required to obtain permission to carry out payment-related activities?",
      answer:
        "Banks and financial institutions licensed by Nepal Rastra Bank are required to obtain permission to carry out payment related activities.",
    },
    {
      id: 10,
      question: "Which organizations can do electronic payment?",
      answer:
        "Only organizations that have received a license from Nepal Rastra Bank to perform payment-related operations can perform payment-related operations.",
    },
    {
      id: 11,
      question: "How much money can be transferred between wallet?",
      answer:
        "Up to Rs.50 thousand per day and up to Rs.5 lakh per month can be transferred from one wallet to another wallet.",
    },
    {
      id: 12,
      question:
        "Does the customer get interest on the amount deposited in the mobile wallet?",
      answer:
        "dPaisa does not provide any interest on the amount deposited in the customer's mobile wallet.",
    },
    {
      id: 13,
      question:
        "Is the amount kept in dPaisa wallet safe/not? Are there any charges for opening an account in dPaisa wallet?",
      answer:
        "Due to being a licensed payment service provider from Nepal Rastra Bank, the amount kept in dPaisa wallet is completely safe. The customer does not have to pay any fees for opening an account in dPaisa wallet.",
    },
    {
      id: 14,
      question:
        "If there is a problem while using the wallet you are using, where can you contact and solve it?",
      answer:
        "If you have any difficulty while using the wallet you are using, you can fill the complaint form on this website or contact us at the contact address.",
    },
    {
      id: 15,
      question:
        "What happens when dealing with payment transactions with unlicensed organizations?",
      answer:
        "There is a possibility that the transaction may be illegal, the transaction may not be authorized, fraud and financial loss may occur when dealing with organizations that have not received permission from Nepal Rastra Bank. So we encourage you to stay safe while using dPaisa. ",
    },
    {
      id: 16,
      question:
        "How many payment institutions are there in operation? Banks and financial institutions can/cannot do payment related work?",
      answer:
        "Apart from banks and financial institutions, currently 37 institutions, including 10 payment system operators and 27 payment service providers, have obtained licenses from Nepal Rastra Bank and are carrying out payment-related activities. An updated list of organizations that have received a license from Nepal Rastra Bank and are operating has been placed on the website of the Payment System Department of Nepal Rastra Bank. Banks and financial institutions licensed by Nepal Rastra Bank can carry out payment related activities with permission.",
    },
    {
      id: 17,
      question: "What do payment service providers do?",
      answer:
        "Payment service providers provide direct services to customers in electronic payment transactions. Those organizations conduct payment transactions through electronic means such as payment of goods, services, assets or other liabilities between customers and service providers, and transfer of funds at home and abroad.",
    },
    {
      id: 18,
      question:
        "How many types of electronic payment related organizations are there in Nepal?",
      answer:
        "There are two types of electronic payment organizations in Nepal namely Payment System Operator (PSO) and Payment Service Provider (PSP).",
    },
    {
      id: 19,
      question: "What is electronic payment?",
      answer:
        "Electronic payment is a payment transaction that is processed through the Internet using electronic devices such as computers, mobiles, and cards. The use of instruments like cash, check is not covered under electronic payment.",
    },
  ];
  const Nepfaqs = [
    {
      id: 1,
      question: "वालेट भनेको के हो? अथवा विद्युतीय वालेट भनेको के हो?",
      answer:
        "वालेट भनेको नेपाल राष्ट्र बैंकबाट स्वीकृतिप्राप्त भुक्तानी सेवा प्रदायकले आफ्ना ग्राहकहरूको नाममा जारी गर्ने विद्युतीय खाता हो । वालेट प्रयोगकर्ताले तोकिएका बैंक तथा वित्तीय संस्था अथवा एजेन्टबाट वालेटमा रकम जम्मा गरी वस्तु तथा सेवा खरिदवापत रकम भुक्तानी गर्न सक्छन् ।",
    },
    {
      id: 2,
      question: "इ-मनी भनेको के हो?",
      answer:
        "बैंक तथा वित्तीय संस्थामा रहेको भौतिक नगदलाई विद्युतीय माध्यमबाट कारोबार गर्न सक्ने गरी विद्युतीय वालेट, प्रिपेड कार्ड वा अन्य विद्युतीय उपकरणमा जम्मा गरिएको रकमलाई इ–मनी भनिन्छ । इ–मनीलाई Virtual Money पनि भनिन्छ ।",
    },
    {
      id: 3,
      question: "विद्युतीय भुक्तानी कारोबार गर्दा के-कस्ता सजगता अपनाउनुपर्दछ?",
      answer:
        "प्रयोगकर्ताहरूले कुनै पनि माध्यमबाट आफ्नो गोप्य Password, One Time Password (OTP) वा संवेदनशील विवरण (जस्तै; खाता नम्बर, जन्म मिति, नागरिकता वा राहदानी नम्बर आदि) अन्य व्यक्तिलाई उपलब्ध गराउनुहुँदैन, आफूले राखेको पासवर्डहरू बेलाबेलामा परिवर्तन गर्नुपर्दछ र यस्ता पासवर्डहरू गोप्य तरिकाले सुरक्षित राख्नुपर्दछ । भुक्तानीका लागि प्रयोग गरिने कार्ड, मोबाइल जस्ता उपकरण चोरी भएमा वा हराएमा सम्बन्धित सेवा प्रदायकलाई तत्काल जानकारी गराई सेवा बन्द (Block) गर्नुपर्दछ ।",
    },
    {
      id: 4,
      question: "विद्युतीय वालेटको माध्यमबाट कति रकमसम्म रकामन्तर गर्न सकिन्छ?",
      answer:
        "वालेटबाट प्रति कारोबार रु.५० हजारसम्म, प्रतिदिन रु.एक लाखसम्म तथा प्रतिमहिना रु.१० लाखसम्म रकमान्तर गर्न सकिन्छ ।  नेपाल राष्ट्र बैंकले समय समयमा निर्देशन जारी गरी यसरी रकमान्तर गर्ने सीमा परिवर्तन गर्न सक्नेछ ।",
    },
    {
      id: 5,
      question: "Quick Response (QR) Code अथवा क्युआर कोड भनेको के हो?",
      answer:
        "यो एक विशेष प्रकारको Bar Code हो जसलाई विद्युतीय भुक्तानी उपकरणले Scan गर्दा त्यसमा संग्रह गरिएका विवरणहरु प्राप्त गर्न सकिन्छ । यसमा भुक्तानी प्राप्त गर्ने मर्चेन्टको खाताको विवरण रहेको हुन्छ । हाल नेपाल राष्ट्र बैंकको निर्देशनानुसार QR Code मार्फत भुक्तानी गर्दा ग्राहकलाई कुनै प्रकारको शुल्क लाग्दैन । उदाहरणको लागि तलको QR Scan गर्दा माथि उल्लिखित परिभाषा प्राप्त गर्न सकिन्छ ।",
      image: require("../assests/barCode.jpg"),
    },
    {
      id: 6,
      question: "Point of Sale (PoS) भनेको के हो?",
      answer:
        "Point of Sale (PoS) भनेको विद्युतीय भुक्तानी स्वीकार गर्ने एक उपकरण हो जसको प्रयोगबाट विभिन्न मर्चेन्ट वा पसलहरूले ग्राहकले खरिद वा उपभोग गरेका बस्तु तथा सेवाको भुक्तानी हाल उपलब्ध विभिन्न कार्डहरुमार्फत प्राप्त गर्न सक्छन् ।",
    },
    {
      id: 7,
      question: "Issuer Bank र Acquirer Bank भनेको के हो?",
      answer:
        "कुनै पनि विद्युतीय भुक्तानी उपकरण जस्तै भुक्तानी कार्ड, QR जारी गर्ने बैंकलाई Issuer Bank भनिन्छ । Issuer Bank बाट जारी भएका विद्युतीय भुक्तानी उपकरण कुनै अर्को बैंकको टर्मिनलबाट प्रयोग भएको खण्डमा उक्त टर्मिनल भएको बैंकलाई Acquirer Bank भनिन्छ ।",
    },
    {
      id: 8,
      question:
        "भुक्तानी कारोबार गर्दा लाग्ने शुल्कसम्बन्धी व्यवस्था के रहेको छ?",
      answer:
        "नेपाल राष्ट्र बैंकको निर्देशनबमोजिम अनुमतिपत्रप्राप्त संस्थाले भुक्तानी कारोबारको प्रकृतिअनुसार लाग्ने शुल्क निर्धारण गरेका हुन्छन् । त्यसरी निर्धारण गरेको शुल्कसम्बन्धी अद्यावधिक विवरण सम्बन्धित संस्थाको Website मा प्रकाशित गरिएको हुन्छ ।",
    },
    {
      id: 9,
      question:
        "नेपाल राष्ट्र बैंकबाट इजाजतपत्रप्राप्त बैंक तथा वित्तीय संस्थाहरूले भुक्तानीसम्बन्धी कार्य गर्न अनुमति लिन आवश्यक छ ?",
      answer:
        "नेपाल राष्ट्र बैंकबाट इजाजतपत्रप्राप्त बैंक तथा वित्तीय संस्थाहले भुक्तानीसम्बन्धी कार्य गर्न अनुमति लिन आवश्यक छ ।",
    },
    {
      id: 10,
      question:
        "विद्युतीय भुक्तानीसम्बन्धी कार्य कुन कुन संस्थाले गर्न सक्छन् ?",
      answer:
        "नेपाल राष्ट्र बैंकबाट भुक्तानीसम्बन्धी कार्य गर्न अनुमतिपत्रप्राप्त संस्थाले मात्र भुक्तानीसम्बन्धी कार्य गर्न सक्छन् ।",
    },
    {
      id: 11,
      question:
        "विद्युतीय वालेट (Wallet) को माध्यमबाट कति रकमसम्म रकमान्तर गर्न सकिन्छ ?",
      answer:
        "एक वालेटबाट अर्को वालेटमा प्रतिदिन रु.५० हजारसम्म तथा प्रतिमहिना रु.५ लाख सम्म रकमान्तर गर्न सकिन्छ ।",
    },
    {
      id: 12,
      question:
        "ग्राहकहरूले खोलेको मोवाइल वालेटमा राखेको रकममा ब्याज पाइन्छ/पाईंदैन ?",
      answer:
        "सामान्यतः ग्राहकहरूले खोलेको मोवाइल वालेटमा राखेको रकममा ब्याज पाईंदैन ।",
    },
    {
      id: 13,
      question:
        "dPaisa वालेटमा राखेको रकम सुरक्षित हुन्छ/हुँदैन ? dPaisa वालेटमा खाता खोले बापत कुनै शूल्क लाग्छ ?",
      answer:
        "नेपाल राष्ट्र बैंकबाट अनुमतिपत्रप्राप्त भुक्तानी सेवा प्रदायक भएका कारण dPaisa वालेटमा राखेको रकम पूर्णरुपमा सुरक्षित रहने व्यवस्था मिलाइएको छ । dPaisa वालेटमा खाता खोलेबापत ग्राहकले कुनै पनि शुल्क तिर्नु पर्दैन।",
    },
    {
      id: 14,
      question:
        "आफूले प्रयोग गरेको वालेट प्रयोग गर्दा कठिनाई आएमा कहाँ कसरी सम्पर्क गरी समाधान गर्न सकिन्छ ?",
      answer:
        "आफूले प्रयोग गरेको वालेट प्रयोग गर्दा कुनै कठिनाई भएमा यसै वेवसाइटमा भएको गुनासो फाराम भरेर वा सम्पर्क ठेगानामा सम्पर्क गर्न सक्नुहुन्छ। ",
    },
    {
      id: 15,
      question:
        "अनुमति नलिएका संस्थासँग भुक्तानी सम्बन्धी कारोबार गर्दा के हुन्छ ?",
      answer:
        "नेपाल राष्ट्र बैंकबाट अनुमति नलिएका संस्थासँग भुक्तानीसम्बन्धी कारोबार गर्दा सो कारोबार गैर-कानुनी हुने, कारोबारको आधिकारिकता नहुने, ठगिने तथा आर्थिक हानि नोक्सानी हुनसक्ने सम्भावना रहन्छ । तसर्थ dPaisa प्रयोग गर्नुभई सुरक्षित रहनु हुन  हामी प्रोत्साहन गर्दछौ। ",
    },
    {
      id: 16,
      question:
        "सञ्चालनमा रहेका भुक्तानीसम्बन्धी कार्य गर्ने संस्थाहरू कतिवटा छन् ? बैंक तथा वित्तीय संस्थाले भुक्तानीसम्बन्धी कार्य गर्ने सक्दछन्/सक्दैनन् ?",
      answer:
        "बैंक तथा वित्तीय संस्थाबाहेक हाल  नेपाल राष्ट्र बैंकबाट १० वटा भुक्तानी प्रणाली सञ्चालक र २७ वटा भुक्तानी सेवा प्रदायक गरी जम्मा ३७ वटा संस्थाले अनुमतिपत्र प्राप्त गरी भुक्तानीसम्बन्धी कार्य गर्दै आइरहेका छन् ।  नेपाल राष्ट्र बैंकबाट अनुमतिपत्र प्राप्त गरी सञ्चालनमा रहेका संस्थाहरूको अद्यावधिक सूची नेपाल राष्ट्र बैंकको भुक्तानी प्रणाली विभागको वेबसाइटमा राखिएको छ ।  नेपाल राष्ट्र बैंकबाट इजाजतपत्रप्राप्त बैंक तथा वित्तीय संस्थाहरूले अनुमति लिई भुक्तानीसम्बन्धी कार्य गर्न सक्दछन् ।",
    },
    {
      id: 17,
      question: "भुक्तानी सेवा प्रदायकले के काम गर्दछन् ?",
      answer:
        "भुक्तानी सेवा प्रदायक संस्थाले विद्युतीय भुक्तानीसम्बन्धी कारोबारमा ग्राहकलाई प्रत्यक्ष सेवा प्रदान गर्दछन् । ती संस्थाले ग्राहक तथा सेवा प्रदायकको बीचमा वस्तु, सेवा, सम्पत्ति वा अन्य दायित्वबापतको रकम भुक्तानी गरिदिने, स्वदेश तथा विदेशमा रकम ट्रान्सफर गर्ने जस्ता विद्युतीय माध्यमबाट गरिने भुक्तानी कारोबार सञ्चालन गर्दछन् ।",
    },
    {
      id: 18,
      question:
        "नेपालमा विद्युतीय भुक्तानी सम्बन्धी काम गर्ने संस्था कति प्रकारका छन् ?",
      answer:
        "भुक्तानी प्रणाली सञ्चालक (Payment System Operator-PSO) र भुक्तानी सेवा प्रदायक (Payment Service Provider-PSP) गरी नेपालमा विद्युतीय भुक्तानीसम्बन्धी काम गर्ने संस्थाहरू दुर्इ प्रकारका छन् ।",
    },
    {
      id: 19,
      question: "विद्युतीय भुक्तानी भनेको के हो ?",
      answer:
        "कम्प्युटर, मोबाइल, कार्ड जस्ता विद्युतीय उपकरणहरूको प्रयोग गरी इन्टरनेटका माध्यमबाट सम्पादन गरिने भुक्तानी कारोबारलाई विद्युतीय भुक्तानी भनिन्छ । नगद, चेक जस्ता उपकरणको प्रयोग विद्युतीय भुक्तानीअन्तर्गत पर्दैन ।",
    },
  ];
  const [language, setLanguage] = useState(Nepfaqs);
  useEffect(
    () => (enabled ? setLanguage(Engfaqs) : setLanguage(Nepfaqs)),
    [enabled]
  );

  return (
    <div className="bg-white my-[48px]">
      <div className="mx-auto max-w-[81vw] px-[24px]  lg:px-[32px] py-[48px]">
        <div className="mx-auto max-w-[81vw] divide-y divide-gray-900/10 ">
          <div
            className="flex flex-col md:flex-row  justify-between items-start 
            md:items-center
                          gap-[16px] 
                          sm:text-[24px]"
          >
            <div >
              Frequently asked questions
            </div>
            {/* switch languages */}
            <Switch.Group
              as="div"
              className="flex items-center justify-between gap-[4px] md:gap-[12px]"
            >
              <span className="flex flex-grow flex-col justify-between gap-[8px] 
              text-[20px] leading-[28px]
              sm:text-[18px] ">
                <Switch.Label
                  as="span"
                  className="font-medium leading-[24px] text-[24px] text-gray-700 "
                  passive
                >
                  English
                </Switch.Label>
                {/* <Switch.Description as="span" className="text-sm text-gray-500">
                  Switch to english language
                </Switch.Description> */}
              </span>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? "bg-[#EC4121]" : "bg-gray-400",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#EC4121] focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {/* FAQs lists */}
          <dl className="mt-[40px] space-y-6 divide-y divide-gray-900/10">
            {language.map((faq) => (
              <Disclosure as="div" key={faq.id} className="pt-[24px]">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between
                       text-left text-gray-900">
                        <span className="text-[16px] font-semibold leading-[24px]">
                          {faq.question}
                        </span>
                        <span className="ml-[24px] flex h-[28px] items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-[24px] w-[24px]"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-[24px] w-[24px]"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-[80px] pr-[48px]">
                      <p className="text-base leading-[28px] text-gray-600">
                        {faq.answer}
                      </p>
                      <div>
                        {faq.image && (
                          <img
                            src={faq?.image}
                            className="object-cover max-h-[300px]"
                            alt="img"
                          />
                        )}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
