import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Home from "./Components/Homepage";
import Blog from "./Components/Blog/Blog";
import Head from "./Components/Header/Head";
import Header3 from "./Components/Header/Header3";
import Footer from "./Components/Footer/Footer";
import Privacy from "./Components/Footer/Privacy and policy/Privacy";
import Scrolltotop from "./Components/Scrolltotop";
import Single from "./Components/Blog/Tranding/Blog/Singlepage/Single";
import Single2 from "./Components/Blog/Tranding/Blog/Singlepage/Single2";
import Single3 from "./Components/Blog/Tranding/Blog/Singlepage/Single3";
import DpaisaFinancial from "./Components/Blog/Tranding/Blog/Singlepage/DpaisaFinancial"
import Offer1 from "./Components/Blog/Offer/offersingle/offer1";
import Servicess from "./Components/Servicess/Servicess";
import FAQ from "./Components/Faq/FAQ";
import FAQs from "./Components/FAQ";
import First from "./Components/Blog/Latest News/First";
import Second from "./Components/Blog/Latest News/Second";
import Security from "./Components/Footer/Information securiy/Security"
import Commision from "./Components/Footer/commision/Comission"
import Transitionlimit from "./Components/Footer/Transitionlimit/Transitionlimit"
import Terms from "./Components/Footer/Terms and condition/Terms"
import HelpAndSupport from "./Components/Header/helpAndSupport/HelpAndSupport"
import HelpAndSupportForm from "./Components/Header/helpAndSupport/HelpAndSupportForm";
function App() {
  return (
    <>
      <BrowserRouter>
        <Scrolltotop />
        <Head />
        <Header3 />
    
        <Routes>
          <Route path="/" element={<Blog />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/services" element={<Servicess />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/support" element={<HelpAndSupport />} />
          <Route path="/support/:helpandsupport" element={<HelpAndSupportForm />} />
          <Route path="/single1" element={<Single />} />
          <Route path="/single2" element={<Single2 />} />
          <Route path="/single3" element={<Single3 />} />
          <Route path="/Financial_Journey" element={<DpaisaFinancial/>}/>
          <Route path="/offer1" element={<Offer1/>} />
          <Route path="/Policy" element={<Privacy />} />
          <Route path="/Security" element={<Security />} />
          <Route path="/Commision" element={<Commision/>} />
          <Route path="/Transactionlimit" element={<Transitionlimit/>} />
          <Route path="/Termsandconditions" element={<Terms/>}/>
          
          {/* zsdfasdf */}
          <Route path="/Latest1" element={< Single/>} />
          <Route path="/Latest2" element={<Second />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
