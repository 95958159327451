import React from "react";

const comission = () => {
  const containerStyle = {
    marginTop: "10px",
  };
  return (
    <div className="containerHead6">
      <div className="container6">
        <div className="tablecontainer">
          <div className="title">
            <h2 style={{fontSize:"38px"}}>
              Transaction Charge
            </h2>
          </div>
          <div className="title">
            <h2>
              1.Agent To Wallet
            </h2>
          </div>

          <table className="custom-table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Charges</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Upto Rs 5000</td>
                <td>Rs 5</td>
              </tr>
              <tr>
                <td>Rs 5001 to 15,000</td>
                <td>Rs 15</td>
              </tr>
              <tr>
                <td>Rs 15,001-25000:</td>
                <td>Rs 25</td>
              </tr>
            </tbody>
          </table>
          <div className="title">
          <h2>
            2.Charges For Tax Related Payment:
          </h2>
        </div>

        <table className="custom-table">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Charges</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Upto Rs 5000</td>
              <td>Rs 5</td>
            </tr>
            <tr>
              <td>Rs 5001 to 10,000</td>
              <td>Rs 8</td>
            </tr>
            <tr>
              <td>Rs 10,001 to above:</td>
              <td>Rs 10</td>
            </tr>
          </tbody>
        </table>
        <div style={containerStyle}>
          <p>
            Note: The charges for wallet to bank transfer is Rs 10.
          </p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default comission;
