import React ,{useState} from "react";
import Login from "../Header/Login Register/Login.jsx";
import "./banner.css";
// import "../Header/Login Register/Login.css";
import img from "../images/Icoons/dPaisa Logo.png";
import img1 from "../images/Icoons/Lady.png"
 const Banner = () => {
  // const [openModel, setOpenModel] = useState(false);
  return (
    <div className="grid0">
      <div className="grid1">
        <img src={img1}/>
      </div>
      <div className="grid2">
        <div className="right">
        <div className="heading">
          <img src={img} />
          <h1>Digital wallet</h1>
        </div>
        <div className="subheading">
          <p>Send Money and Receive Payment instantly anywhere</p>
        </div>
        <div className="subheading1">
          <p>in Nepal using your phone.</p>
        </div>
        <div className="loginreq">
        <div className="auth-buttons1">
        <a href="https://www.dpaisa.com/signin/" ><button className='register'   >Login</button></a>
       <a href="https://www.dpaisa.com/signup/" ><button className='register'  >Register</button></a>
        {/* <Login open ={openModel} onClose={() => setOpenModel(false)}/> */}
      </div>
      </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;