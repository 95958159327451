import React from "react";


const Privacy = () => {
  return (
    <>
      <div className="containerHead6">
        <div className="container6">
          <div className="privacy">
            <h1>Information Security Policy</h1>
          </div>
       
          <p>
          Management team of Goldmine Business Group Pvt.Ltd .Responsible to convince customers, suppliers, merchants, business partners and Financial Institutions and stakeholders for the Secrecy, Accessibility  and Morality of information as per company terms and policy for day to day operation.
          </p>
          <h2>Goldmine business Group Limited</h2>
          <p>
          Implemented an Information Security Management System (ISMS) to prevent data and information of Gold Mine Business Group and its customers from information security risks.


          </p>
          <h2>To meet this Requirment  we will:</h2>
     
          <ul>
            <li>Sustain a productive  Information Security Management System.</li>
            <li>Locate the most suitable technology and framework.</li>
            <li>Ensure policies comply with relevant laws, regulations, and standards.
</li>

<li>
Encounter all the agreement duties on information security
</li>

<li>
    
Implement tools and processes to detect security incidents.
    
</li>
<li>
Maintain a security-sensitive culture within Information Services.
    
</li>
<li>
Regularly detect and enhance the effectiveness  of the Information Security Management System.
    
</li>



          </ul>

<p>      <span>Goldmine business Group limited</span>  shall constantly try for safety, diligence, and excellence to improve the quality of its information security position and to protect the Dpaisa’s information resources.   </p>

       


        </div>
      </div>
    </>
  );
};

export default Privacy;
