import React from "react";
import Offerleft1 from "./offerleft1";



import I2 from "../../../images/Icoons/Apple Icon.png";
import I1 from "../../../images/Icoons/Google Play White_.png";
import Offer from "../../Offer/Offer";
import Playstore from "../../Flight/Playstore";


const Single = () => {



  return (
    <>
      <div className="containerHeadt">
        <div className="containert">
          <div className="division">
            <div className="leftsides">
                <Offerleft1/>
               
              
            </div>
            <div className="rightsides">
              <Offer />
              <Playstore/>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Single;
