import React from "react";
import "./header.css";
import img from "../images/Icoons/Telephone.png"
const Head = () => {
  return (
    <>
 
      <div className="head">
      <div className="containerHead">
        <div className="headleft">
         <img className="image" src={img} alt=""/>
          <span className="num">+977-1-4620147</span>
       
       
        </div>
        <div className="headright">
          <p className="paragraph1">नेपाल राष्ट्र बैंक द्वारा अनुमति प्राप्त भुक्तानी सेवा प्रदायक </p>
        </div>
      </div>
      </div>
      
    </>
  );
};
export default Head;
