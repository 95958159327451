import React, { useState } from "react";
import ReactDom from 'react-dom';
import "./Header3.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import img from "../images/dpaisa-logo.png";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";
import Login from "./Login Register/Login";
import Register from "./Login Register/Register"
const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const [openModel, setOpenModel] = useState(false);
  const [openModel1, setOpenModel1] = useState(false);
  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        
        <div className="logo">
        <Link to="/" className="linkk"><img src={img} /></Link>
        
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
            <Link to="/" className="linkk">Home</Link>
            </li>
            {/* <li>
              About 
            </li> */}
            <li>
            <Link to="/services" className="linkk">Services</Link>
            </li>
            {/* <li>
            <Link to="/blog"className="linkk">Blog</Link>
            </li> */}
            <li>
            <Link to="/policy" className="linkk">Privacy policy</Link>
            </li>

           
            <li>
            <Link to="/FAQs" className="linkk">FAQs</Link>
            </li>
            <li>
            <Link to="/support" className="linkk">Support</Link>
  
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          <ul className="social-media-desktop">
            <li>
              <a href="https://www.dpaisa.com/signin/" className="button" >Login</a >
             
            </li>
            <li>
            <a  className="button1" href="https://www.dpaisa.com/signup/">Register</a >
            </li>
           
          </ul>
          <Login open ={openModel} onClose={() => setOpenModel(false)}/>
          <Register open1 ={openModel1} onClose1={() => setOpenModel1(false)}/>
          {/* hamburget menu start  */}
          <div className="hamburger-menu">
          <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              {showMediaIcons ? <ImCross /> : <GiHamburgerMenu />}
             
            </a>
          </div>
        </div>
      </nav>

     
    </>
  );
};

export default Navbar;