import React from "react";
import { FaRegCopyright } from "react-icons/fa";

import image from "../../assets/logo.png";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  const myBlockofcode = (
    <div className={styles.c4}>
      {/* <h3>CONNECT WITH US</h3> */}
      <div className={styles.icons}>
        <a href="https://www.facebook.com/dpaisa.np/" target="_blank">
          <i className="f fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/dpaisa.np/" target="_blank">
          <i className="i fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/goldmine-np" target="_blank">
          <i className="fab fa-linkedin" />
        </a>

        <i className="fab fa-youtube"></i>
      </div>
    </div>
  );
  const myBlockofcode2 = (
    <div className={styles.c3}>
      {/* <div>
      <h3>JOIN DPAISA</h3>
      </div> */}

      <div className={styles.image}>
        <img src={image} className={styles.imageSize}/>
      </div>
    </div>
  );
  return (
    <div className={styles.footerMain}>
      <div className={styles.containerhead}>
        <div className={styles.footer}>
          <div className={styles.containerHead}>
            <div className={styles.containersection}>
              <div className={styles.row1}>
                <div className={styles.colum3}>
                  <div>
                    <div className={styles.marqueeContainer}>
                      <div className={styles.marqueeContent}>
                        {/* Your marquee content here */}
                      </div>
                    </div>
                  </div>
                  <div>{myBlockofcode2}</div>
                </div>
                <div className={styles.colum}>
                  <h3>COMPANY</h3>
                  <p style={{ color: "white", fontSize: "17px" }}>About us</p>
                  <p>
                    <Link
                      className={styles.link}
                      to="/support"
                      style={{ fontSize: "17px" }}
                    >
                      Help and support
                    </Link>
                  </p>
                  <p style={{ color: "white", fontSize: "17px" }}>Career</p>
                </div>
                <div className={styles.colum}>
                  <h3>CHARGES AND LIMITS</h3>
                  <p>
                    <Link
                      className={styles.link}
                      to="/Commision"
                      style={{ fontSize: "17px" }}
                    >
                      dPaisa Service Charge
                    </Link>
                  </p>
                  <p>
                    <Link
                      className={styles.link}
                      to="/Transactionlimit"
                      style={{ fontSize: "17px" }}
                    >
                      Transaction Limit
                    </Link>
                  </p>
                </div>
                <div className={styles.colum}>
                  <h3>GRIEVANCE OFFICER</h3>
                  <p style={{ color: "white", fontSize: "17px" }}>
                    Rishi Ram Aryal
                  </p>
                  <p style={{ color: "white", fontSize: "17px" }}>
                    +977985-118-8675
                  </p>
                  <p style={{ color: "white", fontSize: "17px" }}>
                    Rishi@dpaisa.com
                  </p>
                  <a href="https://gunaso.nrb.org.np/" target="_blank" rel="noopener noreferrer">
  <p style={{ color: "white", fontSize: "17px" }}>
    NRB Grievance Portal
  </p>
</a>
                </div>
                <div className={styles.colum}>
                  <h3 className={styles.htitle}>CODE OF CONDUCT</h3>
                  <p>
                    <Link
                      className={styles.link}
                      to="/policy"
                      style={{ fontSize: "17px" }}
                    >
                      Privacy and Policy
                    </Link>
                  </p>
                  <Link
                    className={styles.link}
                    to="/Termsandconditions"
                    style={{ fontSize: "17px" }}
                  >
                    Terms and Condition
                  </Link>
                  <Link
                    className={styles.link}
                    to="/Security"
                    style={{ fontSize: "17px" }}
                  >
                   Information Security and Policy
                  </Link>
                  <p style={{ color: "white", fontSize: "17px" }}>
                    Dispute Policy
                  </p>
                  <p style={{ color: "white", fontSize: "17px" }}>
                    Inactive/Dormant Account Policy
                  </p>
                
                </div>
              </div>
              <div className={styles.copyright}>
                <div className="flex justify-center items-center gap-8 ">
                  <h3 className={styles.connect}>Connect us :</h3>
                  {myBlockofcode}
                </div>
                <div className={styles.paragraphh}>
                  <p >
                    {/*<FaRegCopyright /> */}
                    &#169; 2024 Goldmine Business GroupPvt. Ltd. All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
