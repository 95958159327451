import { useState } from "react";
import Style from './modal.module.css';

const Dialog = ({ closeModal }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className={Style.modalWrapper} onClick={closeModal}>
        <div className={Style.modalContainer}>
          <div>
            <h4 className={Style.message}>Feedback submitted successfully</h4>
          </div>
          <div>
            <button className={Style.modalBtn} onClick={closeModal}>OK</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dialog;
