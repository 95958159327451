import React from 'react'
import "./single.css"
import { blog } from "../../../../../assets/data/data";

const Singleleft = () => {

    
  const selectedItem = blog.find((item) => item.id === 2);

  return (
    <div>
        <div className="Single" key={selectedItem.id}>
                <div className="img5">
                  <img src={selectedItem.cover} alt="" />
                </div>
                
                  <h2>{selectedItem.title}.</h2>
                  <p>Dear Valued Customers,<br></br>We are thrilled to announce the latest addition to the dPaisa family – our brand new flight booking 
service, designed to make your travel experience smoother, more convenient, and more rewarding 
than ever before. As a trusted name in mobile wallet solutions, dPaisa is proud to extend our 
offerings to include hassle-free flight bookings, exclusively for our esteemed customers in Nepal.</p>
<p><span>Why Choose Dpaisa for Your Flight Bookings?</span><br></br>
    <span>Seamless Integration: </span>


    With dPaisa Flight Booking, you can now seamlessly plan and manage 
your entire travel itinerary within the same platform. No more juggling between multiple apps or 
websites – everything you need for your journey is right at your fingertips</p>
<p><span>Wide Range of Options:</span> Whether you're jetting off for a business trip, a family vacation, or a 
spontaneous getaway, dPaisa offers a comprehensive selection of flights to suit every budget and 
preference. From budget carriers to premium airlines, we've got you covered.</p>
<p><span>Competitive Pricing: </span> At dPaisa, we believe in providing our customers with the best value for 
their money. That's why you'll find competitive pricing and exclusive deals on flights across a 
wide range of destinations. Say goodbye to inflated fares and hello to savings!
</p>
<p><span>Secure and Convenient Payments:</span> With dPaisa's secure payment gateway, you can book your 
flights with confidence, knowing that your transactions are protected by advanced encryption 
technology. Plus, our seamless checkout process makes it quick and easy to complete your booking 
in just a few taps.</p>

<p><span>Dedicated Customer Support:</span> Should you have any questions or need assistance at any stage of 
your booking process, our dedicated customer support team is here to help. From flight inquiries 
to itinerary changes, we're committed to ensuring that your travel experience is as smooth and 
stress-free as possible</p>

       <p><span>How to Get Started:</span> Getting started with dpaisa Flight Booking is easy. Simply download the dpaisa app from the App 
Store or Google Play Store, or visit our website at www.dpaisa.com. Once you've logged in or 
signed up for an account, navigate to the flight booking section, enter your travel details and select 
the flight that best suits your needs. Then, proceed to checkout, make your payment securely, and 
voila – your flight is booked!
<br></br>Thank you for choosing dPaisa for your travel needs. We look forward to serving you and helping 
you create unforgettable travel memories, one flight at a time.<br></br>Happy travels!

<br></br>Warm regards
<br></br>Team dPaisa.

</p> 
<p><span></span></p>        



              </div>





    </div>
  )
}

export default Singleleft