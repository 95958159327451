
import React from "react";



import Offer from "../../../Offer/Offer";
import Singleleft from "./Singleleft";
import Playstore from "../../../Flight/Playstore";

const Single = () => {



  return (
    <>
      <div className="containerHeadt">
        <div className="containert">
          <div className="division">
            <div className="leftsides">
                <Singleleft/>
              
            </div>
            <div className="rightsides">
              <Offer />
              <Playstore/>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Single;
