import React from 'react'
import "./Offer.css"
import { Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import { tpost } from '../../../assets/data/data'
const Offer = () => {
  return (
    <>
   
    <section className='tpost'>
    <Heading title="Exclusive Offer"/>
    <div className="gridmax">
        {tpost.map((val) => {
          return (
            <div className='box1 '>
              <div className='img1'>
                <img src={val.cover} alt='' />
              </div>
              <div className='text1'>
              <Link to={`/offer${val.id}`} className='link'>
                  <h3>{val.title.slice(0, 50)}...</h3>
                </Link>
              
                
              </div>
            </div>
          )
        })}
        </div>
      </section>
    
    </>
  )
}

export default Offer