import { img } from "./download.png";
export const blog = [
  {
    id: 1,
    title:
      "Realizing Economic Potential: The Contribution of dPaisa and the Digital Economy to Nepal's Development ",
    desc: "Nepal is a developing nation that faces many obstacles in its quest for development and economic growth.",
    category: "NATTU KAKA",
    cover: require("./image1.png"),
    date: "Feb 18, 2024",
  },
  {
    id: 2,
    title:
      "Introducing Dpaisa Flight Booking: Your Passport to Seamless Travel",
    desc: "Dear Valued Customers, We are thrilled to announce the latest addition to the dPaisa family – our brand new flight booking service, designed to make your travel experience smoother, more convenient, and more rewarding than ever before ",
    category: "CLUSTERD CLACE",
    cover: require("./Layer12.png"),
    date: "Feb 15, 2024",
  },
  {
    id: 3,
    title:
      "Safeguarding Your dpaisa Transactions: Ensuring Secure Communication for Mobile Payments ",
    desc: "Mobile payments are now the standard for ease and effectiveness in financial transactions in today's technologically advanced society. Users may now easily transfer money, pay bills, and make purchases with a few touches on their smartphones thanks to services like dpaisa.",
    category: "CLUSTER DERY",
    cover: require("./Layer13.png"),
    date: "Feb 20, 2024",
  },
  
];

export const latest = [
  {
    id: 1,
    title: "Fees & charges in Dpaisa",
    desc: "Dpaisa Fees & Charges refers to the costs associated with using the Dpaisa payment platform or financial services. These fees and charges may vary depending on the specific services offered by Dpaisa and the terms and conditions outlined by the company.",
    cover: require("./images.jpeg"),
    destination: "Commision",
  },
  {
    id: 2,
    title: "DPaisa's Financial Journey: Overcoming Challenges in FY 2080-81",
    desc: "For the fiscal year 2080-81, DPaisa's financial performance reflected significant challenges. The company generated a revenue of NPR 649,637.14, but after accounting for the cost of goods sold, the gross profit stood at NPR 159,586.44. Despite an additional indirect income of NPR 511,042.59, the operating profit recorded a loss of NPR 9,952,377.00 due to substantial administrative and indirect expenses.",

    cover: require("./downolad.jpeg"),
    destination: "Financial_Journey",
  },
];

export const tpost = [
  {
    id: 1,
    title:
      "  Presenting Dpaisa Cashback Bonanza: Unlock Amazing Prizes with Each Purchase! ",
    cover: require("./Layer17.png"),
  },
  {
    id: 2,
    title: "Online Recharge: Cashback of up to 0.4% ",
    cover: require("./Layer18.png"),
  },
  {
    id: 3,
    title: "Reward for Mobile Top-Up: Up to 2%",
    cover: require("./Layer19.png"),
  },
  // {
  //   id: 4,
  //   title: "Mount Etna erupts fashion in nice looking pictures",
  //   cover: "../images/tpost/Layer 17.png",
  // },
  // {
  //   id: 5,
  //   title: "Mount Etna erupts fashion in nice looking pictures",
  //   cover: "../images/tpost/Layer 20.png",
  // },
  // {
  //   id: 6,
  //   title: "Mount Etna erupts fashion in nice looking pictures",
  //   cover: "../images/tpost/Layer 4.png",
  // },
  // {
  //   id: 7,
  //   title: "Mount Etna erupts fashion in nice looking pictures",
  //   cover: "../images/tpost/tpost3.jpg",
  // },
];
export const faqs = [
  {
    id: 1,
    question: "What is Dpaisa?",
    answer:
      "Consectetur adipisicing elit. Non ipsa dolorem, rem consequatur eum omnis ex, fuga temporibus qui nesciunt odio aliquam commodi culpa inventore ut similique repellendus perferendis sint!",
  },
  {
    id: 2,
    question: "How does Dapisa work?",
    answer:
      "Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui. Eos, omnis beatae? Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit.",
  },
  {
    id: 3,
    question: "What are its services?",
    answer:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
  },
  {
    id: 4,
    question: "What are its benefits?",
    answer:
      "Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate? Qui totam est omnis dolor nobis quisquam veritatis a!",
  },
  {
    id: 5,
    question: "How do I get started?",
    answer:
      "Maiores fuga, cum praesentium esse laudantium! Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui.",
  },
  {
    id: 6,
    question: "should i take trainning to become an expert?",
    answer:
      "Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate.",
  },
];

export const Support = [
  {
    id: 1,
    Image: require("../../assests/HelpAndSupport/KYC.png"),
    title: "KYC Verification",
    subtitle: "KYC Unverified",
    subtitle1: "Document submission...",
  },
  {
    id: 2,
    Image: require("../../assests/HelpAndSupport/mobiletopup.png"),
    title: "Mobile Recharge",
    subtitle: "Incorrect Top-up",
    subtitle1: "Amount not reflecting",
  },
  {
    id: 3,
    Image: require("../../assests/HelpAndSupport/internetBillPayment.png"),
    title: "Internet Bill Payment",
    subtitle: "Incorrect username/",
    subtitle1: "Package payment error",
  },
  {
    id: 4,
    Image: require("../../assests/HelpAndSupport/khanipani.png"),
    title: "Water Utility",
    subtitle: "Service Issues",
    subtitle1: "Water bill concerns",
  },
  {
    id: 5,
    Image: require("../../assests/HelpAndSupport/bus.png"),
    title: "Bus Ticketing",
    subtitle: "Booking Errors",
    subtitle1: "Ticket issues",
  },
  {
    id: 6,
    Image: require("../../assests/HelpAndSupport/flight.png"),
    title: "Flight Booking",
    subtitle: "Travel Experience",
    subtitle1: "Convenience issues",
  },
  {
    id: 7,
    Image: require("../../assests/HelpAndSupport/withdrawal.png"),
    title: "Bank Withdrawal",
    subtitle: "Amount not deposited",
    subtitle1: "in bank account",
  },
  {
    id: 8,
    Image: require("../../assests/HelpAndSupport/loadmoney.png"),
    title: "Add Funds",
    subtitle: "Incorrect Load,",
    subtitle1: "Amount not credited",
  },
  {
    id: 9,
    Image: require("../../assests/HelpAndSupport/government (2).png"),
    title: "Government Payment",
    subtitle: "Unprocessed Amount",
    subtitle1: "Payment mistake",
  },
  {
    id: 10,
    Image: require("../../assests/HelpAndSupport/QR.png"),
    title: "QR Code Payment",
    subtitle: "Unclear Transaction",
    subtitle1: "Pending transaction",
  },
];

