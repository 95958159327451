import React from 'react'
import I2 from "../../images/Icoons/Apple Icon.png";
import I1 from "../../images/Icoons/Google Play White_.png";
const Playstore = () => {
  return (
    <div>
          <div className="subscribe">
              <h1 className="store1">
                Payment Make Easy.
                <br />
                <span>App avaliable for free.</span>
              </h1>
              <div className="botton1">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dpaisa&pcampaignid=web_share" className="android1">
                  <img src={I1} alt="" />
                  <h3>Google Play</h3>
                </a>
                <a target="_blank" href="https://apps.apple.com/us/app/dpaisa-digital-payment-nepal/id1555859739" className="android1">
                  <img src={I2} alt="" />
                  <h3>Apple Store</h3>
                </a>
              </div>
            </div>




    </div>
  )
}

export default Playstore