import React from "react";
import I2 from "../../images/Icoons/Apple Icon.png";
import I1 from "../../images/Icoons/Google Play White_.png";
import "./flight.css";
import Heading from "../Heading/Heading";
import img from "../../../assets/images/Flight.png"
// import img1 from "../../../assets/images/Layer 12.png"

import { AiOutlineUser, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai"
import Latest from "../Latest News/Latest";
import Offer from "../Offer/Offer";
import Playstore from "./Playstore";
const Flight = () => {
  return (
    <div className="containerHeadt ">
      <div className="containert">
        <div className="division">
          <div className="leftsides">
            {/* <Heading title="Flight" />
            <div className="flight">
             <div className="img2"> <img src={img1}/></div>
              <div className="description">
                <h6>Lorem ipsum dolor sit amet consectetur</h6>
                <div className='date1'>
                 <div><AiOutlineUser className='icon' /> <label htmlFor=''>Buddha Airlines</label></div> 
                <div> < AiOutlineClockCircle className='icon1' /> <label htmlFor=''>March 27, 2024</label></div> 
                
                </div>
                <p> possimus praesentium ipsam vel quo, sunt tempora quos? Sapiente dolore nulla, temporibus dignissimos omnis, explicabo ea a sequi sit quaerat corrupti eum. Est, magnam quam culpa ipsum porro unde veritatis, maxime, recusandae aperiam cumque inventore assumenda distinctio ex obcaecati facere! Laudantium!Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, facilis?</p>
              </div>
            </div>
            <div className="top">
            <div className="flight">
              <div className="img2"><img src={img}/></div>
              <div className="description">
                <h6>Lorem ipsum dolor sit amet consectetur</h6>
                <div className='date1'>
                 <div><AiOutlineUser className='icon' /> <label htmlFor=''>Buddha Airlines</label></div> 
                <div> < AiOutlineClockCircle className='icon1' /> <label htmlFor=''>March 27, 2024</label></div> 
                
                </div>
                <p> possimus praesentium ipsam vel quo, sunt tempora quos? Sapiente dolore nulla, temporibus dignissimos omnis, explicabo ea a sequi sit quaerat corrupti eum. Est, magnam quam culpa ipsum porro unde veritatis, maxime, recusandae aperiam cumque inventore assumenda distinctio ex obcaecati facere! Laudantium!Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, facilis?</p>
              </div>
            </div>

            </div> */}
            <Latest/>


          </div>
          <div className="rightsides">
            <div className="submit">
            {/* <section className="subscribe">
              <h1>News Letters</h1>

              <p className="par"> 5,00,000 subscribers</p>
              <form action="">
                <input className="in" type="email" placeholder="Email" />
                <button>
                  <i className="fa fa-paper-plane"></i> SUBMIT
                </button>
                <p className="par">
                  By Signing up, you agree our{" "}
                  <span className="pa">Privacy policy</span>
                </p>
              </form>
            </section> */}
           

          <Playstore/>
            </div>
           


            <Offer/>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Flight;
