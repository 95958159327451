import React from "react";
import "./single.css";
import { latest } from "../../../../../assets/data/data";

const Singleleft = () => {
    const openFullscreen = (element) => {
      if (!document.fullscreenElement) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }
    };

  const handleImageClick = (e) => {
    openFullscreen(e.target);
  };

  const selectedItem = latest.find((item) => item.id === 2);

  return (
    <div>
      <div className="Single" key={selectedItem.id}>
        <div className="img5">
          <img 
            src={selectedItem.cover} 
            alt={selectedItem.title} 
            onClick={handleImageClick} // Add the onClick handler here
          />
        </div>

        <h2>{selectedItem.title}</h2>
        <p>
          DPaisa faced a challenging fiscal year in 2080-81, with a gross profit of NPR 159,586.44 from a revenue of NPR 649,637.14, but high operational costs led to a significant net loss of NPR 12,091,484.03. Despite generating indirect income and securing additional funds through share capital and unsecured loans, the company struggled with substantial administrative and depreciation expenses. DPaisa's total sources of funds were NPR 19,036,319.71, but negative reserves and liabilities highlighted the financial strain. The company made efforts to manage its resources, but the year ended with a significant deficit.
        </p>
        <p>
          <span>DPaisa's Struggle and Resilience in FY 2080-81:</span> The fiscal year 2080-81 was a tough period for DPaisa, as the company faced significant financial challenges. Despite generating a revenue of NPR 649,637.14, the high cost of goods sold left the gross profit at NPR 159,586.44. The situation was further complicated by substantial administrative expenses amounting to NPR 10,623,006.03, resulting in an operating loss of NPR 9,952,377.00. Additional indirect expenses, including depreciation and amortization, further deepened the financial strain, culminating in a net loss of NPR 12,091,484.03. Yet, DPaisa demonstrated resilience by managing its funds and liabilities, despite the economic hurdles.
        </p>
        <p>
          <span>A Year of Financial Adjustment: DPaisa’s FY 2080-81 Performance:</span> The financial year 2080-81 marked a significant period of financial adjustment for DPaisa. Although the company managed to secure a total revenue of NPR 649,637.14, the cost structure, including a high cost of goods sold and substantial administrative expenses, resulted in a challenging year with a net operating loss of NPR 9,952,377.00. Indirect income of NPR 511,042.59 provided some relief, but it was insufficient to offset the mounting costs. With further depreciation, amortization, and finance costs, the net profit after tax stood at a loss of NPR 12,091,484.03. DPaisa’s management focused on optimizing resource allocation and handling current liabilities to navigate through this period of financial strain.
        </p>
        <p>
          <span>Navigating Financial Pressures: DPaisa’s Strategic Financial Position in FY 2080-81:</span> In FY 2080-81, DPaisa faced significant financial pressures that necessitated strategic management of its financial position. With total sources of funds amounting to NPR 19,036,319.71, primarily driven by share capital and an advance against share capital, DPaisa faced the challenge of balancing these against negative reserves and accumulated losses totaling NPR 77,615,524.34. The company also secured an unsecured loan of NPR 16,478,214.33 to meet its financial obligations. On the application side, DPaisa invested in non-current assets worth NPR 7,088,643.25 and maintained current assets of NPR 23,695,864.77. However, the company had to manage current liabilities of NPR 11,748,188.31, leaving it with net current assets of NPR 11,947,676.46. This delicate financial balancing act highlights DPaisa’s strategic approach to navigating a challenging fiscal environment.
        </p>
        <p>
          <span>DPaisa's Financial Snapshot: Analyzing the Fiscal Challenges of 2080-81:</span> The financial snapshot of DPaisa for the year 2080-81 reveals a company grappling with substantial fiscal challenges. Revenue generation, while crucial, was overshadowed by high operational costs, leading to an operating loss of NPR 9,952,377.00. Indirect income and additional sources of funds were not enough to counterbalance the escalating expenses, including depreciation and finance costs. The year ended with a net loss of NPR 12,091,484.03, reflecting the broader economic difficulties faced by the company. Despite these challenges, DPaisa’s financial position showed a strategic deployment of resources, with investments in non-current assets and careful management of current liabilities. This analysis underscores the complexity of the financial hurdles DPaisa encountered during the fiscal year, highlighting areas for future growth and stability.
        </p>
      </div>
    </div>
  );
};

export default Singleleft;
