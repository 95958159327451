import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Dialog from "./HelpAndSupportModal";
import Style from "./HelpAndSupportForm.module.css";
import { useParams } from "react-router-dom";

const FormComponent = () => {
  const inputRef = useRef();
  const { helpandsupport } = useParams();
  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    services: Yup.string().required("Service is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.mixed().required("Image is required"),
  });

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleFileChange(e) {
    const img = e.currentTarget.files[0];
    console.log("The image before setting state:", img);
    setImage(img); // Store the actual file object in the state
  }

  // This will log the updated image state whenever it changes

  useEffect(() => {
    if (image) {
      console.log("The image after state update:", image);
    }
  }, [image]);

  // Initialize useForm with Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    // Create FormData object to handle file uploads
    const formData = new FormData();
    formData.append("services", data.services);
    formData.append("description", data.description);
    formData.append("image", image); // Append the file object

    console.log("FormData before sending to API:", formData);

    const filteredData = {
      services: data.services,
      description: data.description,
      image: data.image[0], // Assuming a single file is selected
    };
    console.log(filteredData, "fdfdfdfd");
    try {
      const response = await axios.post(
        "http://172.31.251.2:5052/api/feedbacks",
        filteredData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            " App-Authorizer": "647061697361",
          },
        }
      );
      console.log("Response:", response.data);

      if (response.data.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={Style.containerHead}>
      <div className={Style.container}>
        <div className={Style.subContainer}>
          <div className={Style.title}>
            <h1>Feedback form</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="services">Choose Service:</label>
              <input
                type="text"
                id="services"
                {...register("services")}
                value={helpandsupport}
              />
              {errors.services && <p>{errors.services.message}</p>}
            </div>

            <div>
              <label htmlFor="description">Description:</label>
              <textarea id="description" {...register("description")} />
              {errors.description && <p>{errors.description.message}</p>}
            </div>

            <div>
              <label htmlFor="image">Choose Image:</label>

              <input
                type="file"
                {...register("image")}
                id="image"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />

              {errors.image && <p>{errors.image.message}</p>}
            </div>

            <button type="submit" disabled={loading} className={Style.btn}>
              {loading ? <div className={Style.loadingSpinner}>Loading...</div> : "Proceed"}
              
            </button>
            {success && <Dialog closeModal={() => setSuccess(false)} />}
          </form>
        </div>
      </div>
    </div>
  );
};
export default FormComponent;
